import { createRouter, createWebHistory } from "vue-router";
import pathLoader from "../plugins/loader";
// import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Login",
    component: pathLoader("login", "index"),
  },
  {
    path: "/reset",
    name: "Reset",
    component: pathLoader("reset", "index"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: pathLoader("reset-password", "index"),
  },
  {
    path: "/setup-password",
    name: "Setup",
    component: pathLoader("setup", "index"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
